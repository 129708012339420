import React, { useEffect, useMemo, useState } from "react";
import "./UserAssignmentsRequest.scss";
import Checkbox from "common-components/checkbox/Checkbox";
import ApiButton from "common-components/api-button/ApiButton";
import { Loader, MailIcon } from "@gitlab-rtsensing/component-library";
import { UserAssignment, getUserAssignments } from "api/user-assignments";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStatusManager } from "global/use-status-manager";
import { getBusinessOwners } from "api/active-directory";
import produce from "immer";
import { colors } from "utils";
import { CreateSubmissionsMassRequestItem, createSubmissionsMass } from "api";

const namespace = "curation-user-assignments-request";

type Props = {
  workspaceId: string;
  workstreamComponentName: string;
};

type AssignmentItem = UserAssignment & {
  isChecked: boolean;
};

export const UserAssignmentsRequest = (props: Props) => {
  const { data: apiUas, status: getUasStatus } = useQuery(
    ["user-assignments", props.workspaceId],
    () => getUserAssignments(props.workspaceId, props.workstreamComponentName)
  );

  const { data: apiUsers, status: getUsersStatus } = useQuery(
    ["business-owners"],
    () => getBusinessOwners()
  );

  const usersMap = useMemo(() => {
    const map = new Map();

    if (!apiUsers) {
      return map;
    }

    for (const user of apiUsers.users) {
      map.set(user.email, user.fullName);
    }

    return map;
  }, [apiUsers]);

  const [assignments, setAssignments] = useState<AssignmentItem[]>([]);
  const [isCheckAllChecked, setIsCheckAllChecked] = useState<
    boolean | "partial"
  >(false);

  const { mutate: requestMassSubmissions, status: requestMsStatus } =
    useMutation(createSubmissionsMass, {
      onMutate: () => {
        overrideReqMsStatus("requestMassSubmissions", "loading");
      },
      onSuccess: data => {
        const erroredRequests = data.submissionResponses.filter(
          r => r.errors.length
        );

        //uncheck successful requests
        const newAssignments = produce(assignments, draftState => {
          for (const assignment of draftState) {
            assignment.isChecked = !!erroredRequests.find(
              er => er.id === assignment.id
            );
          }
        });

        setAssignments(newAssignments);

        if (erroredRequests.length) {
          if (
            isCheckAllChecked === true &&
            newAssignments.length !== erroredRequests.length
          ) {
            setIsCheckAllChecked("partial");
          }

          overrideReqMsStatus("requestMassSubmissions", "error");
          console.error("Error mass-requesting submissions", erroredRequests); // eslint-disable-line no-console
        } else {
          setIsCheckAllChecked(false);
          overrideReqMsStatus("requestMassSubmissions", undefined);
        }
      }
    });

  const { status, overrideStatus } = useStatusManager(
    getUasStatus,
    getUsersStatus
  );

  const { status: requestMsFinalStatus, overrideStatus: overrideReqMsStatus } =
    useStatusManager(requestMsStatus);

  useEffect(() => {
    if (!apiUas) {
      overrideStatus("ua-request-loader", "loading");
      return;
    }

    overrideStatus("ua-request-loader", undefined);

    setAssignments(
      apiUas.assignments
        .map<AssignmentItem>(a => ({
          isChecked: false,
          ...a
        }))
        .sort((a, b) => a.itemName.localeCompare(b.itemName))
    );
  }, [apiUas, overrideStatus]);

  const onCheckAllClick = () => {
    if (isCheckAllChecked === true) {
      setIsCheckAllChecked(false);

      const newAssignments = produce(assignments, draftState => {
        for (const assignment of draftState) {
          assignment.isChecked = false;
        }
      });

      setAssignments(newAssignments);
    } else {
      setIsCheckAllChecked(true);

      const newAssignments = produce(assignments, draftState => {
        for (const assignment of draftState) {
          if (!assignment.userEmail) {
            continue;
          }

          assignment.isChecked = true;
        }
      });

      setAssignments(newAssignments);
    }
  };

  const onCheckboxClick = (i: number) => {
    const newAssignments = produce(assignments, draftState => {
      draftState[i].isChecked = !draftState[i].isChecked;
    });

    setAssignments(newAssignments);

    let anyChecked = false;
    let allChecked = true;

    for (const assignment of newAssignments) {
      if (!assignment.userEmail) {
        continue;
      }

      anyChecked = anyChecked || assignment.isChecked;
      allChecked = allChecked && assignment.isChecked;

      if (anyChecked && !allChecked) {
        break;
      }
    }

    if (allChecked) {
      setIsCheckAllChecked(true);
    } else if (anyChecked) {
      setIsCheckAllChecked("partial");
    } else {
      setIsCheckAllChecked(false);
    }
  };

  return (
    <div className={namespace}>
      <Loader status={status}>
        <div className={`${namespace}-description`}>
          Select items below to send a submission request. Owners have been
          pre-populated based on prior assignments.
        </div>
        <div className={`${namespace}-list`}>
          <div className={`${namespace}-list-header`}>
            <Checkbox
              checked={isCheckAllChecked === true}
              indeterminate={isCheckAllChecked === "partial"}
              onClick={onCheckAllClick}
            />
            <div>Item Name</div>
            <div>Business Owner</div>
          </div>
          <div className={`${namespace}-list-items`}>
            {assignments.map((a, i) => (
              <React.Fragment key={i}>
                <Checkbox
                  checked={a.isChecked}
                  disabled={!a.userEmail}
                  onClick={() => onCheckboxClick(i)}
                />
                <div className={`${namespace}-list-items-name`}>
                  {a.itemName}
                </div>
                {a.userEmail ? (
                  <div className={`${namespace}-list-items-owner`}>
                    {usersMap.get(a.userEmail)}
                  </div>
                ) : (
                  <div className={`${namespace}-list-items-owner-missing`}>
                    No owner set
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={`${namespace}-footer`}>
          <ApiButton
            type="primary"
            label="send request"
            midCallLabel="sending..."
            successMsg="Requests sent to selected business owners."
            errorMsg="An unexpected error has occured. Affected items have remained checked."
            status={requestMsFinalStatus}
            showStatusToast
            icon={<MailIcon fill={colors.white} />}
            iconPosition="right"
            disabled={isCheckAllChecked === false}
            onClick={() => {
              const requests = assignments
                .filter(a => a.isChecked)
                .map<CreateSubmissionsMassRequestItem>(a => ({
                  id: a.id,
                  snapshotJsonUrl: a.snapshotJsonUrl,
                  submission: {
                    ...a,
                    userEmail: a.userEmail || "",
                    title: a.itemName
                  }
                }));

              requestMassSubmissions({ submissionRequests: requests });
            }}
          />
        </div>
      </Loader>
    </div>
  );
};

export default UserAssignmentsRequest;
