/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import SubmissionSubmit from "./submission-submit/SubmissionSubmitPage";
import { LoginCallback } from "@okta/okta-react";
import SubmissionReview from "./submission-review/SubmissionReviewPage";
import SubmissionView from "./submission-view/SubmissionViewPage";
import UserAssignmentsRequestPage from "./user-assignments/request/UserAssignmentsRequestPage";
import UserAssignmentsManagePage from "./user-assignments/manage/UserAssignmentsManagePage";
import SubmissionCancelledPage from "./submission-cancelled/SubmissionCancelledPage";
import SubmissionProcessedPage from "./submission-processed/SubmissionProcessedPage";
import SubmissionPendingReviewPage from "./submission-pending-review/SubmissionPendingReviewPage";

const pages = {
  path: "/curation",
  component: undefined as unknown as JSX.Element, //undefined needed to not create route, but casting needed for cleaner updateChildrenProperties

  submission: {
    path: "/submission/:submissionId",
    go(submissionId: string): string {
      return replacePathVariables(this.path, { submissionId });
    },
    component: <SubmissionSubmit />,

    review: {
      path: "/review",
      go(submissionId: string): string {
        return replacePathVariables(this.path, { submissionId });
      },
      component: <SubmissionReview />
    },

    view: {
      path: "/view",
      go(submissionId: string): string {
        return replacePathVariables(this.path, { submissionId });
      },
      component: <SubmissionView />
    },

    cancelled: {
      path: "/cancelled",
      go(submissionId: string): string {
        return replacePathVariables(this.path, { submissionId });
      },
      component: <SubmissionCancelledPage />
    },

    processed: {
      path: "/processed",
      go(submissionId: string): string {
        return replacePathVariables(this.path, { submissionId });
      },
      component: <SubmissionProcessedPage />
    },

    pendingReview: {
      path: "/pending",
      go(submissionId: string): string {
        return replacePathVariables(this.path, { submissionId });
      },
      component: <SubmissionPendingReviewPage />
    }
  },

  userAssignments: {
    path: "/user-assignments/:workspaceId/:workstreamComponentName",

    request: {
      path: "/request",
      go(workspaceId: string, workstreamComponentName: string): string {
        return replacePathVariables(this.path, {
          workspaceId,
          workstreamComponentName
        });
      },
      component: <UserAssignmentsRequestPage />
    },

    manage: {
      path: "/manage",
      go(workspaceId: string, workstreamComponentName: string): string {
        return replacePathVariables(this.path, {
          workspaceId,
          workstreamComponentName
        });
      },
      component: <UserAssignmentsManagePage />
    }
  },

  notfound: {
    path: "/notfound",
    go(): string {
      return this.path;
    },
    component: <div>404 NOT FOUND</div>
  },

  unauthorized: {
    path: "/unauthorized",
    go(): string {
      return this.path;
    },
    component: <div>403 NOT AUTHORIZED</div>
  },

  loginCallback: {
    path: "/implicit/callback",
    component: <LoginCallback />
  }
};

function replacePathVariables(
  str: string,
  params?: { [key: string]: string }
): string {
  let newStr = str.toString();

  if (params) {
    const paramKeys = Object.keys(params);
    for (let i = 0; i < paramKeys.length; i++) {
      const paramKey = paramKeys[i];
      newStr = newStr.replace(`:${paramKey}`, params[paramKey]);
    }
  }

  return newStr;
}

const skippedProperties = ["path", "go", "component"];

(function updateChildrenProperties(
  pathObj,
  parentPath: string,
  parentComponent: JSX.Element
) {
  //update path
  const currentPath = parentPath + (pathObj.path || "");
  pathObj.path = currentPath; // eslint-disable-line no-param-reassign
  pathObj.component = pathObj.component || parentComponent; // eslint-disable-line no-param-reassign

  for (const [key, value] of Object.entries(pathObj)) {
    if (skippedProperties.includes(key)) continue;

    updateChildrenProperties(value as any, currentPath, pathObj.component);
  }
})(pages, "", undefined as unknown as JSX.Element);

export default pages;
