import React, { useContext } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./UserAssignmentsRequestPage.scss";
import BusinessOwnersTabNavigation from "components/user-assignments/tab-navigation/UserAssignmentsTabNavigation";
import BusinessOwnersRequest from "components/user-assignments/request/UserAssignmentsRequest";
import { useParams } from "react-router-dom";

const namespace = "curation-user-assignments-request-page";

export default function BusinessOwnersRequestPage(): JSX.Element {
  const perms = useContext(PermissionsContext).businessOwners.request;
  const { workspaceId = "", workstreamComponentName = "" } = useParams();

  return (
    <div className={namespace}>
      <PermissionsWrapper permissions={perms}>
        <div className={`${namespace}-content`}>
          <div className={`${namespace}-content-white-container`}>
            <BusinessOwnersTabNavigation
              workspaceId={workspaceId}
              workstreamComponentName={workstreamComponentName}
            />
            <BusinessOwnersRequest
              workspaceId={workspaceId}
              workstreamComponentName={workstreamComponentName}
            />
          </div>
        </div>
      </PermissionsWrapper>
    </div>
  );
}
