import React, { useEffect, useMemo, useState } from "react";
import "./UserAssignmentsManage.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  UserAssignment,
  getUserAssignments,
  putUserAssignments
} from "api/user-assignments";
import { Loader } from "@gitlab-rtsensing/component-library";
import { Dropdown, DropdownItem } from "common-components";
import produce from "immer";
import { getBusinessOwners } from "api/active-directory";
import { useStatusManager } from "global/use-status-manager";
import ApiButton from "common-components/api-button/ApiButton";

const namespace = "curation-user-assignments-manage";

type Props = {
  workspaceId: string;
  workstreamComponentName: string;
};

export const UserAssignmentsManagement = (props: Props) => {
  const { data: apiUas, status: getUasStatus } = useQuery(
    ["user-assignments", props.workspaceId],
    () => getUserAssignments(props.workspaceId, props.workstreamComponentName)
  );

  const { data: apiUsers, status: getUsersStatus } = useQuery(
    ["business-owners"],
    () => getBusinessOwners()
  );

  const { mutate: saveUserAssignments, status: saveStatus } =
    useMutation(putUserAssignments);

  const { status, overrideStatus } = useStatusManager(
    getUasStatus,
    getUsersStatus
  );

  const [assignments, setAssignments] = useState<UserAssignment[]>([]);
  useEffect(() => {
    if (!apiUas) {
      overrideStatus("ua-manage-loader", "loading");
      return;
    }

    overrideStatus("ua-manage-loader", undefined);

    const newAssignments = produce(apiUas, draftState => {
      draftState.assignments.sort((a, b) =>
        a.itemName.localeCompare(b.itemName)
      );
    });

    setAssignments(newAssignments.assignments);
  }, [apiUas, overrideStatus]);

  const users = useMemo<DropdownItem[]>(() => {
    if (!apiUsers) {
      return [];
    }

    return apiUsers.users
      .map<DropdownItem>(u => ({
        label: u.fullName,
        value: u.email
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [apiUsers]);

  const onChange = (id: number, userEmail: string) => {
    const newAssignments = produce(assignments, draftState => {
      const asgmt = draftState.find(a => a.id === id);
      if (!asgmt) {
        return;
      }

      asgmt.userEmail = userEmail;
    });

    setAssignments(newAssignments);
  };

  return (
    <div className={namespace}>
      <Loader status={status}>
        <div className={`${namespace}-description`}>
          Assign owners to each item below. These owners will be pre-populated
          when sending requests.
        </div>
        <div className={`${namespace}-list`}>
          <div className={`${namespace}-list-header`}>
            <div>Item Name</div>
            <div>Business Owner</div>
          </div>
          <div className={`${namespace}-list-items`}>
            {assignments.map((a, i) => (
              <React.Fragment key={i}>
                <div>{a.itemName}</div>
                <div>
                  <Dropdown
                    data={users}
                    value={a.userEmail}
                    onSelect={userEmail => onChange(a.id, userEmail)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={`${namespace}-footer`}>
          <ApiButton
            type="primary"
            label="Save"
            midCallLabel="Saving..."
            status={saveStatus}
            showStatusToast
            successMsg="Business owners saved."
            onClick={() => saveUserAssignments(assignments)}
          />
        </div>
      </Loader>
    </div>
  );
};

export default UserAssignmentsManagement;
