import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union,
} from "runtypes";
import { UserValidator } from "./common";

const WorkstreamValidator = Record({
  id: Number,
  workstream: String
});

const FormFieldControlValidator = Record({
  id: Number,
  type: Union(
    Literal("input"),
    Literal("textarea"),
    Literal("radio"),
    Literal("color status"),
    Literal("links")
  )
});

export type FormFieldControl = Static<typeof FormFieldControlValidator>;

const RadioCheckboxOption = Record({
  id: Number,
  fieldOption: String
});

//#region form
const FormFieldValidator = Record({
  id: Number,
  label: String,
  formOrder: Number,
  control: FormFieldControlValidator, //TODO JCG: change to not be array
  required: Boolean,
  options: Array(RadioCheckboxOption).nullable()
});

export type FormField = Static<typeof FormFieldValidator>;

const FormValidator = Record({
  id: String,
  title: String.nullable(),
  description: String,

  createdDate: String,
  createdBy: String,

  workstream: WorkstreamValidator,

  fields: Array(FormFieldValidator),

  skipApproval: Boolean
});

export type Form = Static<typeof FormValidator>;
//#endregion

//#region submissions
const SubmissionStatusValidator = Union(
  Literal("created"),
  Literal("submitted"),
  Literal("approved"),
  Literal("rejected"),
  Literal("cancelled"),
  Literal("processed"),
  Literal("in review")
);

const SubmissionStatusRecordValidator = Record({
  id: Number,
  status: SubmissionStatusValidator
});

const SubmissionFieldEntriesValidator = Record({
  fieldId: Number,
  liveValue: String.nullable(),
  submittedValue: String.nullable(),
  finalValue: String.nullable()
});

const SubmissionAltWorkflowValidator = Record({
  id: String,
  name: String,
  description: String.nullable()
});

const AltIdSet = Record({
  id: Number,
  nextStepId: Number.nullable(),
  prevStepId: Number.nullable(),
}) 

const SubmissionAltWorkflowStepValidator = Record({
  id: Number,
  key: String,
  name: String,
  description: String.nullable(),
  isInitialStep: Boolean,
  isFinalStep: Boolean,
  reviewers: Array(UserValidator),
  isNextStepSkippable: Boolean,
  allSteps: Array(AltIdSet)
}).nullable();

export type SubmissionField = Static<typeof SubmissionFieldEntriesValidator>;

export const SubmissionValidator = Record({
  id: String,
  formId: String,
  title: String,
  status: SubmissionStatusRecordValidator,
  rejectReason: String.nullable(),

  fieldEntries: Array(SubmissionFieldEntriesValidator),
  form: FormValidator,

  sourceMetadata: String.nullable(),
  assignee: UserValidator,
  requester: UserValidator,

  createdDate: String,
  createdBy: String,
  submittedDate: String.nullable(),
  submittedBy: String.nullable(),
  reviewedDate: String.nullable(),
  reviewedBy: String.nullable(),
  updatedDate: String,
  updatedBy: String,

  altWorkflow: SubmissionAltWorkflowValidator.nullable(),
  altWorkflowStep: SubmissionAltWorkflowStepValidator.nullable(),
  skipNextApproval: Boolean,
  isFirstStepSkippable: Boolean.nullable()
});

export type Submission = Static<typeof SubmissionValidator>;
//#endregion
