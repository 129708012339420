import React, { useEffect, useState } from "react";
import "./SubmissionFormSubmitRadioButtons.scss";
import { Radio } from "@gitlab-rtsensing/component-library";
import { FormMode } from "components/submission-forms/common/field-container/SubmissionFieldContainer";
import { Submission } from "api";

const namespace = "curation-submission-form-submit-radio-buttons";

export type SubmitMode = "edit" | "readonly";

type Props = {
  formMode: FormMode;
  submitMode: SubmitMode | undefined;
  submission: Submission | undefined;
  onChange: (submitMode: SubmitMode) => void;
  isDisabled: boolean;
};

export const SubmissionFormSubmitRadioButtons = (props: Props) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (props.formMode === "submit" && props.submission) {
      if (props.submission.fieldEntries.every(fe => fe.liveValue === null)) {
        setIsHidden(true);
        props.onChange("edit");
      }
    } else if (props.formMode === "resubmit") {
      props.onChange("edit");
    }
  }, [props.formMode, props.submission]); // eslint-disable-line react-hooks/exhaustive-deps

  if (props.formMode !== "submit" || isHidden) {
    return <></>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-label`}>Please select an option below:</div>
      <div className={`${namespace}-buttons-container`}>
        <Radio
          variant="primary"
          value="Edit Submission"
          checked={props.submitMode === "edit"}
          onChange={() => {
            if (!props.isDisabled) {
              props.onChange("edit");
            }
          }}
          disabled={props.isDisabled} // doesn't actually work, but might in future version, and change styling
        />
        <Radio
          variant="primary"
          value="No Updates Needed"
          checked={props.submitMode === "readonly"}
          onChange={() => {
            if (!props.isDisabled) {
              props.onChange("readonly");
            }
          }}
          disabled={props.isDisabled} // doesn't actually work, but might in future version, and change styling
        />
      </div>
    </div>
  );
};

export default SubmissionFormSubmitRadioButtons;
